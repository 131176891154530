/*
@contract home-page {
  background: BackgroundColor
  color: Color
  container-max-width: MaxWidth
  font-size: FontSize
  overflow: Overflow
}
*/

.homePageMainContainer {
  --container-max-width: var(--home-page-container-max-width);
  --container-padding: 0 var(--space-6x);
  --heading-color: var(--home-page-color);
  --heading-font-size: var(--home-page-heading-font-size-mobile);
  --heading-font-weight: var(--home-page-heading-font-weight-mobile);
  background: var(--home-page-background);
  color: var(--home-page-color);
  font-size: var(--home-page-font-size);
  line-height: var(--leading-relaxed);
  overflow: var(--home-page-overflow);

  @media (--screen-lg) {
    --home-page-font-size: var(--font-size-lg);
    --heading-font-size: var(--home-page-heading-font-size-desktop);
    --heading-font-weight: var(--home-page-heading-font-weight-desktop);
    --heading-letter-spacing: -0.03em;
  }
}

.seoContentSection {
  --heading-text-align: center;
  --heading-font-size: var(--font-size-4xl);
  margin-top: var(--space-28x);
  margin-bottom: var(--space-28x);

  @media (--screen-lg) {
    --heading-font-weight: 700;
    --heading-font-size: var(--font-size-5xl);
  }
}

.seoContentSectionHTML {
  p {
    margin-bottom: var(--space-4x);
  }

  &::before {
    display: block;
    width: 60px;
    height: 8px;
    content: '';
    background: var(--brand-divider-background-dark);
    margin: var(--space-11x) auto;
  }
}
