/*
@contract nearby-properties {
  background-color: BackgroundColor
  heading-color: Color
  copy-color: Color
}
*/

.nearbyProperties {
  --listing-card-preview-info-card-shadow: 0 20px 32px -2px rgb(0 0 0 / 10%);
  --listing-card-preview-border: 0;
  --heading-line-height: var(--nearby-properties-line-height);
  --heading-letter-spacing: var(--nearby-properties-letter-spacing);
  background-color: var(--nearby-properties-background-color);
}

.placesNearbyContainer {
  padding-left: 0;
  padding-right: 0;
}

.nearbyPropertiesHeader {
  --heading-font-size: var(--nearby-properties-heading-font-size);
  --heading-margin: var(--nearby-properties-heading-margin);
  --heading-color: var(--nearby-properties-heading-color);
  margin: var(--nearby-properties-header-margin);
}

.nearbyPropertiesCopy {
  display: var(--nearby-properties-copy-display);
  color: var(--nearby-properties-copy-color);
}

.nearbyPropertiesGrid {
  display: var(--nearby-properties-grid-display);
  overflow-x: auto;
  grid-auto-flow: column;
  grid-auto-columns: 315px;
  column-gap: var(--space-4x);
  row-gap: var(--nearby-properties-grid-row-gap);
  line-height: var(--leading-body);
  margin-top: var(--nearby-properties-grid-margin-top);
  padding: var(--nearby-properties-grid-padding);

  @media (--screen-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-flow: row;
    grid-auto-columns: auto;
    grid-auto-rows: auto;
  }

  @media (--screen-lg) {
    grid-template-columns: var(--nearby-properties-grid-template-columns);
  }

  @media (--screen-xl) {
    margin-right: 0;
  }
}

.nearbyPropertiesGridSkeleton {
  background: var(--listing-card-preview-card-background-color-dark);
  border-radius: var(--listing-card-preview-border-radius);
  min-height: 390px;
}
